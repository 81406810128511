import { createRoot } from "react-dom/client";
import { StrictMode, lazy, Suspense } from "react";
import { kcContext as kcLoginThemeContext } from "./app/keycloak-theme/login/context";
import { ThemeProvider, CssBaseline } from 'fronton-react';
import { ToastContainer } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';

const KcLoginThemeApp = lazy(() => import("./app/keycloak-theme/login/ui"));

if (typeof window !== 'undefined') {
    injectStyle();
}

createRoot(document.getElementById("root")!).render(
    <StrictMode>
        <CssBaseline>
            <ThemeProvider>
                <ToastContainer
                    position="bottom-center"
                    autoClose={7500}
                    theme="colored"
                />
                <Suspense>
                    {(() => {
                        if (kcLoginThemeContext !== undefined) {
                            return <KcLoginThemeApp kcContext={kcLoginThemeContext} />;
                        }

                        throw new Error(
                            "This app is a Keycloak theme" +
                            "It isn't meant to be deployed outside of Keycloak"
                        );
                    })()}
                </Suspense>
            </ThemeProvider>
        </CssBaseline>
    </StrictMode>
);
