import {createGetKcContext} from "keycloakify/login";

export type KcContextExtension = {
    pageId: "webauthn-register.ftl",
    challenge: string,
    userid: string,
    username: string,
    signatureAlgorithms: string,
    rpEntityName: string,
    rpId: string,
    attestationConveyancePreference: string,
    authenticatorAttachment: string,
    requireResidentKey: string,
    userVerificationRequirement: string,
    createTimeout: string,
    excludeCredentialIds: string,
    execution: "webauthn-register" | "webauthn-register-passwordless",
} | {
    pageId: "webauthn-error.ftl",
    message: {
        summary: string,
    },
    execution: string
};

export const {getKcContext} = createGetKcContext<KcContextExtension>({
    mockData: [
        {
            pageId: "login.ftl",
            locale: {
                currentLanguageTag: "ru",
            },
            message: undefined,
            realm: {
                internationalizationEnabled: false,
                registrationAllowed: false,
            },
            social: {displayInfo: false},
        },
    ],
});

export const {kcContext} = getKcContext({
    mockPageId: "login.ftl",
});

export type KcContext = NonNullable<
    ReturnType<typeof getKcContext>["kcContext"]
>;
